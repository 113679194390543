<template>
  <SearchPage
              icon="marketing-search"
              :title="data.title.prefix + ' ' + data.title.value">
    <template #content>
      <Results :columns="columns"
               :items="data.results"
               :meta="meta"
               :loading="loading"
               :no-result-message="t('marketingResults.noResult')"
               :per-page="[10, 20, 50]"
               @page-change="pageChange" />
    </template>
  </SearchPage>
</template>

<script setup lang="ts">
  import { ref, onMounted, watch } from "vue";
  import { http, t, route, init } from "@/logic";

  init();

  const loading = ref(false);
  const columns = ref([
    {
      value: t("marketing.columns.name"),
      prop: "name",
      type: "url",
      width: "25%",
      class: "ellipsis",
    },
    {
      value: t("marketing.columns.address"),
      prop: "address",
      width: "25%",
      class: "ellipsis",
    },
    {
      value: t("marketing.columns.registrationNumber"),
      prop: "registrationNumber",
      width: "10%",
    },
    {
      value: t("marketing.columns.taxNumber"),
      prop: "taxNumber",
      width: "10%",
    },
    {
      value: t("marketing.columns.status"),
      prop: "status",
      type: "icon",
      width: "20%",
    },
    {
      value: t("marketing.columns.marketing"),
      prop: "marketing",
      type: "marketing",
      width: "10%",
    },
  ]);
  const data = ref({
    items: [],
    title: "",
  } as any);
  const meta = ref({
    currentPage: 1,
    perPage: 10,
    count: 0,
    order: "",
  });

  async function load() {
    let query = `?query=${encodeURIComponent(route.query.query as string)}
								 &currentPage=${meta.value.currentPage}&perPage=${meta.value.perPage}`;
    await http.fetchWithLoading(data, loading, `/api/quick-search${query}`);
    meta.value.count = data.value.meta.count;
  }

  async function onPerPage() {
    meta.value.currentPage = 1;
    await load();
  }

  async function pageChange(event) {
    meta.value.currentPage = event.page + 1;
    meta.value.perPage = event.rows;
    await load();
  }

  onMounted(async () => {
    await load();
  });

  watch(() => meta.value.currentPage, load);
  watch(() => route.query, load);
</script>

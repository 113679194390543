import { Ref } from "vue";
import axios from "axios";
import FileDownload from "js-file-download";

export class Http {
  public async get(url) {
    return (await axios.get(url)).data;
  }

  public async post(url, body) {
    return (await axios.post(url, body)).data;
  }

  public async postForm(url, body) {
    return (await axios.postForm(url, body)).data;
  }

  public async delete(url) {
    return (await axios.delete(url)).data;
  }

  public async fetch(value: Ref, url) {
    value.value = (await axios.get(url)).data;		
  }

  public async fetchWithLoading(value: Ref, loading: Ref<boolean>, url) {
    loading.value = true;
    value.value = (await axios.get(url)).data;
    loading.value = false;
    
  }

  public async blob(url, loading = null, open = false) {
    if (loading) {
      loading.value = true;
    }
    await axios({
      url: url,
      responseType: "blob",
    }).then((response) => {
      const name = decodeURI(response.headers["x-filename"]).replace(/\+/g, ' ');
      if (open) {
        const url = window.URL.createObjectURL(new File([response.data], name, {type: "application/pdf"}));
        window.open(url);
      } else {
        FileDownload(response.data, name);
      }      
    });
    if (loading) {
      loading.value = false;
    }
  }
}
<template>
  <div :class="currentRating.class"
       class="indicator">
    <div class="indicator__content">
      <div class="indicator__content__indicator">
        <CompanyRatingRoundChart :score="props.indicatorValue"
                                 :lite="props.lite" />
      </div>
      <div v-if="props.indicatorValue === null"
           class="indicator__content__description">
        <p v-if="!props.disabled"
           class="inactive-text">
          {{ t('summary.indicator.empty') }}
        </p>
      </div>
      <div v-else
           class="indicator__content__description">
        <div class="indicator__content__description__list">
          <p v-for="rating in ratings"
             :key="rating.key">
            <span v-if="rating.key === currentRating.key"
                  class="indicator__content__description__title"
                  :class="rating.class"
                  style="cursor: pointer"
                  @click="onInfoClicked">
              <span class="indicator__content__description__title__value">
                {{ props.indicatorAlpha }}
              </span>
              <span class="indicator__content__description__title__text">
                {{ t('summary.indicator.level.' + rating.key ) }}
              </span>
            </span>
            <span v-else
                  class="indicator__content__description__list__item"
                  :class="rating.class">
              {{ t('summary.indicator.level.' + rating.key ) }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, nextTick } from "vue";
  import { t } from "@/logic";
  import { useEventBus } from "@vueuse/core";

  const props = defineProps(["indicatorValue", "indicatorAlpha", "disabled", "lite"]);
  
  const bus = useEventBus<string>("widgets/show");
  const contentInfoOpened = ref(false);
  const fullRatings = ref([
    {
      from: 85,
      key: "minimalRisk",
      class: "minimal-risk",
    },
    {
      from: 67,
      key: "lowRisk",
      class: "low-risk",
    },
    {
      from: 51,
      key: "mediumRisk",
      class: "medium-risk",
    },
    {
      from: 34,
      key: "risky",
      class: "risky",
    },
    {
      from: 16,
      key: "veryRisky",
      class: "very-risky",
    },
    {
      from: 0,
      key: "extremelyRisky",
      class: "extremely-risky",
    },
  ]);
  const liteRatings = ref([
    {
      from: 80,
      key: "minimalRisk",
      class: "minimal-risk",
    },
    {
      from: 45,
      key: "mediumRisk",
      class: "medium-risk",
    },
    {
      from: 0,
      key: "veryRisky",
      class: "very-risky",
    },
  ]);

  const ratings = computed(() => {
    return props.lite ? liteRatings.value : fullRatings.value;
  });

  const currentRating = computed(() => {
    if (!props.indicatorValue) {
      return {
        from: null,
        key: "inactive",
        class: "inactive",
      };
    }

    return ratings.value.find((r) => r.from <= props.indicatorValue);
  });

  const ratingLabels = computed(() => {
    return ratings.value.filter((r) => r.key !== currentRating.value.key);
  });

  async function onInfoClicked() {    
    bus.emit("RatingDetails", true);
    await nextTick();
    document.getElementById("ratingDetails").scrollIntoView();    
  }
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .indicator {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
  }
}

.indicator {
  flex: 1;
  width: 100%;
  margin: $margin-card 0 0;
  padding: $padding-card;
  border-top-left-radius: 500px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 500px;

  &.inactive {
    box-shadow: $box-shadow-indicator-inactive;

    & .indicator__content__description__title {
      color: $color-gray;
    }
  }

  &.minimal-risk {
    box-shadow: $box-shadow-indicator-minimal-risk;

    & .indicator__content__description__title {
      color: $color-green;
    }
  }

  &.low-risk {
    box-shadow: $box-shadow-indicator-low-risk;

    & .indicator__content__description__title {
      color: $color-lime;
    }
  }

  &.medium-risk {
    box-shadow: $box-shadow-indicator-medium-risk;

    & .indicator__content__description__title {
      color: $color-yellow;
    }
  }

  &.risky {
    box-shadow: $box-shadow-indicator-risky;

    & .indicator__content__description__title {
      color: $color-orange;
    }
  }

  &.very-risky {
    box-shadow: $box-shadow-indicator-very-risky;

    & .indicator__content__description__title {
      color: $color-orange-dark;
    }
  }

  &.extremely-risky {
    box-shadow: $box-shadow-indicator-extremely-risky;

    & .indicator__content__description__title {
      color: $color-red;
    }
  }

  &__info {
    z-index: $z-index-indicator-info;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    /*position: absolute;*/
    /*top: 5rem;*/
    /*right: 3.5rem;*/
    margin-left: 1rem;
    border: 2px solid $color-border-secondary;
    border-radius: 50%;

      &:hover {
        cursor: pointer;
      }

    &::after {
      color: $color-blue;
      font-size: 2.6rem;
      font-family: $font-icon;
      content: "\e92a";
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__indicator {
      width: 18rem;

      & img {
        width: 100%;
      }
    }

    &__description {
      & .inactive-text {
        color: $color-font-primary;
        font-weight: 500;
      }

      &__title {
        display: flex;
        align-items: center;
        font-weight: 700;
        line-height: 2.2rem;

        &__value {
          margin-right: 0.3rem;
          font-size: 2.2rem;
        }

        &__text {
          margin: 0;
          //font-size: 1.4rem;
          white-space: nowrap;
        }
      }

      &__list {
        color: $color-dark-gray;

        &__item {
          display: flex;
          align-items: center;
          margin: 1rem 0;
          font-size: 1.4rem;

          &.minimal-risk,
          &.low-risk,
          &.medium-risk,
          &.risky,
          &.very-risky,
          &.extremely-risky {
            &::before {
              display: block;
              width: 1rem;
              min-width: 1rem;
              height: 1rem;
              min-height: 1rem;
              margin: 0 1rem;
              border-radius: 50%;
              content: "";
            }
          }

          &.minimal-risk {
            &::before {
              background-color: $color-green;
            }
          }

          &.low-risk {
            &::before {
              background-color: $color-lime;
            }
          }

          &.medium-risk {
            &::before {
              background-color: $color-yellow;
            }
          }

          &.risky {
            &::before {
              background-color: $color-orange;
            }
          }

          &.very-risky {
            &::before {
              background-color: $color-orange-dark;
            }
          }

          &.extremely-risky {
            &::before {
              background-color: $color-red;
            }
          }
        }
      }
    }
  }
}
.na {
  color: $color-font-primary !important;
}
</style>

<template>
  <div class="financialReports">
    <div class="row">
      <div class="col-12 col-md-6">
        <MinMaxField v-model="model.fixedAssets" id="financial-fixed-assets"
                     label="marketing.financialDetails.inputs.investedResources"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.currentAssets" id="financial-current-assets"
                     label="marketing.financialDetails.inputs.currentAssets"
                     placeholder="marketing.financialDetails.inputs" />
                     
                     <MinMaxField v-model="model.provisions" id="financial-provisions"
                     label="marketing.financialDetails.inputs.reservationGoals"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.liabilities" id="financial-liabilities"
                     label="marketing.financialDetails.inputs.responsibilities"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.shareholdersEquity" id="financial-shareholders-equity"
                     label="marketing.financialDetails.inputs.ownCapital"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.subscribedCapital" id="financial-subscribed-capital"
                     label="marketing.financialDetails.inputs.registeredCapital"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.domesticSales" id="financial-domestic-sales"
                     label="marketing.financialDetails.inputs.inlandIncome"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.exportSales" id="financial-export-sales"
                     label="marketing.financialDetails.inputs.foreignIncome"
                     placeholder="marketing.financialDetails.inputs" />
      </div>
      <div class="col-12 col-md-6">
        <MinMaxField v-model="model.totalSales" id="financial-total-sales"
                     label="marketing.financialDetails.inputs.netIncome"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.ebitda" id="financial-ebitda"
                     label="marketing.financialDetails.inputs.ebitda"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.accruedAndDeferredAssets" id="financial-accrued-and-deferred-assets"
                     label="marketing.financialDetails.inputs.activeTimeLimitation"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.accruedAndDeferredLiabilities" id="financial-accrued-and-deferred-liabilities"
                     label="marketing.financialDetails.inputs.passiveTimeLimitation"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.incomeFromOperations" id="financial-income-from-operations"
                     label="marketing.financialDetails.inputs.operatingActivityResult"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.incomeBeforeTaxes" id="financial-income-before-taxes"
                     label="marketing.financialDetails.inputs.resultBeforeTaxation"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.accountsPayable" id="financial-accounts-payable"
                     label="marketing.financialDetails.inputs.demands"
                     placeholder="marketing.financialDetails.inputs" />

        <MinMaxField v-model="model.corporateTax" id="financial-corporate-tax"
                     label="marketing.financialDetails.inputs.corporateTax"
                     placeholder="marketing.financialDetails.inputs" />
      </div>
      <div class="col-12">
        <p class="millions">
          {{ t('marketing.financialDetails.millions') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { t } from "@/logic";

  const model = defineModel() as any;
</script>

<style scoped lang="scss">
.financialReports {
  & .millions {
    margin-top: 3rem;
    font-weight: 600;
    font-size: 1.2rem;
  }
}
</style>

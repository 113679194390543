<template>
  <div class="navigation">
    <div ref="clickoutside"
         :class="{ 'active': isHamburgerActive }"
         class="hamburger-navigation hamburger-navigation--stack"
         @click="toggleHamburger">
      <div class="inner">
        <span class="bar" />
        <span class="bar" />
        <span class="bar" />
      </div>
    </div>
    <div class="logo" />
    <div ref="clickoutsidesearch"
         class="company-search">
      <div ref="searchField"
           style="width: 100%">
        <AutoComplete v-model="search" 
                      :suggestions="companies" 
                      :min-length="3" 
                      option-label="name.value"
                      class="quick-search"
                      style="width: 100%"
                      :auto-option-focus="false"
                      :placeholder="t('main.search.placeholder')"
                      :emptySearchMessage="t('main.search.noResult')"
                      @complete="searchCompany"
                      @item-select="openReport"
                      @keyup="autoCompleteKeyUp">
          <template #option="slotProps">
             <div class=" mb-1">
              <div class="d-flex align-items-center">
                <div class="company-status">
                  <div class="icon"
                      :class="slotProps.option.status.icon.key"
                      :style="{color: slotProps.option.status.icon.color}" />
                </div>
                <div class="px-3">
                  <div>{{ slotProps.option.name.value }}</div>
                  <div><small>{{ slotProps.option.address }}</small></div>
                </div>
              </div>
             </div>
          </template>
        </AutoComplete>
      </div>
      <button @click="quickSearch">
        <span>{{ t('main.search.search') }}</span>
      </button>
      <div class="detailed-search" 
           @click="router.push({name: 'advanced.search'})">
        {{ t('main.detailed') }}
      </div>
    </div>
    <div class="user">
      <span>{{ user.name }}</span>
    </div>
    <div ref="menu"
         :class="{ 'active': isHamburgerActive }"
         class="user-mobile">
      <div class="user-avatar-background">
        <img src="/assets/shared/banner.jpg"
             alt="Banner">
      </div>
      <ul @click="toggleHamburger">
        <div class="sub-navigation show">
          <div class="d-flex flex-row-reverse justify-content-end align-items-center">
            <router-link :to="{name: 'home'}"
                         :class="{active: route.name === 'home'}">
              {{ t('main.navigation.home') }}
            </router-link>
            <div class="icon monitoring" />
          </div>
          <div class="d-flex flex-row-reverse justify-content-end align-items-center">
            <router-link :to="{name: 'monitoring'}" 
                         :class="{active: route.name === 'monitoring'}">
              {{ t('main.navigation.monitoring') }}
            </router-link>
            <div class="icon summary" />
          </div>
          <div class="d-flex flex-row-reverse justify-content-end align-items-center">
            <router-link :to="{name: 'marketing.search'}" 
                         :class="{active: route.name === 'marketing.search'}">
              {{ t('main.navigation.marketing') }}
            </router-link>
            <div class="icon marketing" />
          </div>
          <div class="d-flex flex-row-reverse justify-content-end align-items-center">
            <router-link :to="{name: 'international.search'}"
                         :class="{active: route.name === 'international.search'}">
              {{ t('main.navigation.international') }}
            </router-link>
            <div class="icon marketing-web" />
          </div>
          <div class="d-flex flex-row-reverse justify-content-end align-items-center">
            <router-link :to="{name: 'favorites'}"
                         :class="{active: route.name === 'favorites'}">
              {{ t('main.navigation.favourites') }}
            </router-link>
            <div class="icon home-favorites" />
          </div>
          <div class="d-flex flex-row-reverse justify-content-end align-items-center">
            <router-link :to="{name: 'comparison'}" 
                         :class="{active: route.name === 'comparison'}">
              {{ t('main.navigation.comparison') }}
            </router-link>
            <div class="icon company-added" />
          </div>
          <div class="d-flex flex-row-reverse justify-content-end align-items-center">
            <router-link :to="{name: 'send-message'}"
                         :class="{active: route.name === 'send-message'}">
              {{ t('main.navigation.sendMessage') }}
            </router-link>
            <div class="icon send-message" />
          </div>
          <hr class="mobile-menu-separator">
        </div>
        <li @click="router.push({name: 'profile-edit'})">
          <div class="mobile-menu">
            <div class="icon profile-user" />
            <span class="text">{{ t('main.user.modifyProfile') }}</span>
          </div>
        </li>
        <li @click="router.push({name: 'profile-settings'})">
          <div class="mobile-menu">
            <div class="icon home-settings" />
            <span class="text">{{ t('main.user.functionalSettings') }}</span>
          </div>
        </li>
        <li @click="logout()">
          <div class="mobile-menu">
            <div class="icon profile-exit" />
            <span class="text">{{ t('main.user.logout') }}</span>
          </div>
        </li>
        <hr class="mobile-menu-separator">
        <div class="language-selector">
          <div v-if="currentLang !== 'hu'"
               class="language"
               @click="changeLocale('hu')">
            <span class="fi fi-hu"></span>
            <span style="margin-left: 0.5rem">{{ t('languages.hu') }}</span>
          </div>
          <div v-if="currentLang !== 'en'"
               class="language"
               @click="changeLocale('en')">
            <span class="fi fi-gb"></span>
            <span style="margin-left: 0.5rem">{{ t('languages.en') }}</span>
          </div>
          <div v-if="currentLang !== 'de'"
               class="language"
               @click="changeLocale('de')">
            <span class="fi fi-de"></span>
            <span style="margin-left: 0.5rem">{{ t('languages.de') }}</span>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">	
  import { ref, onMounted } from "vue";
  import { onClickOutside  } from "@vueuse/core";
  import { http, t, toggle, route, router, init, user } from "@/logic";
  import AutoComplete from "primevue/autocomplete";

  init();

  const search = ref("");  
  const isHamburgerActive = ref(false);
  const companies = ref([]);
  const currentLang = ref(user.locale);

  const clickoutside = ref(null);
  const clickoutsidesearch = ref(null);

  onClickOutside(clickoutside, event => hide());

  const searchField = ref(null);
  const menu = ref(null);
  const popupItem = ref(null);

  function autoCompleteKeyUp(e) {
    if (e.key == 'Enter') {
      quickSearch();
    }
  }

  async function logout() {
    await user.logout();
    router.push("/login");
  }

  function toggleHamburger() {
    toggle(isHamburgerActive);

    if (isHamburgerActive.value) {
      popupItem.value = menu.value;
    }
  }

  function hide() {
    isHamburgerActive.value = false;
  }

  async function searchCompany() {
    const { results } = (await http.get("/api/autocomplete?query=" + encodeURIComponent(search.value)));
    companies.value = results
  }

  function changeLocale(locale) {
    user.locale = locale;
    location.reload();
  }

  function openReport(event) {
    window.location.href = event.value.name.url;
  }

  function quickSearch() {
    if (search.value?.length >= 3) {
      router.push({
        name: "quick.search",
        query: { query: search.value },
      });
    }    
  }

  onMounted(() => {
    popupItem.value = menu.value;
  });
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  padding: 0 $padding-nav;
  background-color: $color-nav-bg;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: flex-start;
    height: initial;
    padding: $padding-nav;
  }

  & .logo {
    width: 19.24rem;
    height: 3.5rem;
    background: url("/assets/shared/credit-online-logo.svg") center/100% no-repeat;

    @include media-breakpoint-down(lg) {
      margin-bottom: 2rem;
    }
  }

  & .company-search {
    position: absolute;
    left: 50%;
    z-index: $z-index-company-search;
    display: flex;
    align-items: center;
    transform: translateX(-50%);

    @include media-breakpoint-down(lg) {
      position: relative;
      left: initial;
      width: 100%;
      transform: initial;
    }

    & button {
      position: relative;
      display: flex;
      align-items: center;
      height: 3.5rem;
      padding: 0 1rem;
      color: white;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1;
      text-transform: uppercase;
      background-color: $color-blue;
      border: 0;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;

        &:hover {
          cursor: pointer;
        }

      &::before {
        display: flex;
        align-items: center;
        width: 1.8rem;
        height: 3.5rem;
        margin-right: 0.2rem;
        font-size: 1.8rem;
        font-family: $font-icon;
        content: "\e945";
      }

      &:focus {
        outline: none;
      }

      @include media-breakpoint-only(xs) {
        & span {
          display: none;
        }

        &::before {
          margin-right: 0;
        }
      }
    }

    & .detailed-search {
      display: flex;
      align-items: center;
      margin-left: 1.5rem;
      color: $color-font-primary;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1;
      text-transform: uppercase;
      cursor: pointer;

      &::before {
        margin-right: 0.5rem;
        color: $color-primary;
        font-size: 2.3rem;
        font-family: $font-icon;
        visibility: visible;
        content: "\e944";

        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }

      @include media-breakpoint-down(lg) {
        width: 2rem;
        visibility: hidden;
      }

        &:hover {
          text-decoration: none;
        }
    }
  }

  & .user {
    display: inline-flex;
    align-items: center;
    min-width: 0;
    max-width: 18rem;
    margin-right: 3rem;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    & span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .user-avatar {
      position: relative;
      width: 4rem;
      height: 4rem;
      margin-left: 1rem;
      overflow: hidden;
      border-radius: 50%;

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-mobile {
      position: fixed;
      top: 0;
      right: 0;
      z-index: $z-index-hamburger-menu;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30rem;
      height: 100vh;
      margin-right: -30rem;
      background-color: white;
      box-shadow: none;
      transition: margin-right 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

      &.active {
        margin-right: 0;
        box-shadow: $box-shadow-navigation;
      }

      & .user-avatar {
        position: absolute;
        top: $padding-nav;
        left: $padding-nav;
        width: 9rem;
        height: 9rem;
        overflow: hidden;
        border: 2px solid white;
        border-radius: 50%;
        box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.15);

        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &-background {
          position: relative;
          width: 100%;
          min-height: 6rem;
          overflow: hidden;
          background-color: white;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);

          & img {
            position: absolute;
            top: 50%;
            left: calc(50% - 4rem);
            width: 175%;
            transform: translate(-50%, -50%);
            opacity: 0.6;
          }
        }
      }

      & ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 2rem 0 0;
        padding: 0 1rem;
        overflow: auto;
        list-style: none;

        & .language-selector {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          margin: 0;

          & .language {
            display: flex;
            align-items: center;
            margin: 1rem 0;
            cursor: pointer;

            &:hover {
              & span {
                color: $color-primary;
              }
            }

            & img {
              height: 1.5rem;
              margin-right: 1.5rem;
            }

            & span {
              color: $color-font-primary;
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 1;
            }
          }
        }

        & .mobile-menu-separator {
          width: 90%;
          height: 1px;
          background-color: black;
          border-radius: 50%;
          opacity: 0.1;
        }

        & .sub-navigation {
          display: none;

          &.show {
            @include media-breakpoint-down(lg) {
              display: flex;
              flex-direction: column;
            }
          }

          & .icon {
            margin-right: 1rem;
            font-size: 2.4rem;
          }

          & a {
            display: flex;
            align-items: center;
            padding: 1rem 0;
            color: $color-font-primary;
            font-size: 1.6rem;
            text-decoration: none;
            transition: color 0.2s ease-in-out;

              &:hover {
                color: $color-primary;
                cursor: pointer;

                & + .icon {
                  color: $color-primary;
                }
              }


            &.active {
              color: $color-primary;

              & + .icon {
                color: $color-primary;
              }
            }
          }
        }

        & li {
          margin: 1rem 0;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          & .mobile-menu {
            display: flex;
            align-items: center;

              &:hover {
                cursor: pointer;

                & .icon,
                & .text {
                  color: $color-primary !important;
                }
              }


            & .icon {
              margin-right: 1rem;
              color: $color-font-primary;
              font-size: 2.4rem;
              transition: color 0.2s ease-in-out;
            }

            & .text {
              color: $color-font-primary;
              font-weight: 400;
              font-size: 1.6rem;
              transition: color 0.2s ease-in-out;
            }
          }
        }
      }
    }
  }
}
</style>

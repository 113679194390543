<template>
  <div class="container text-center" v-if="loading">
    <ProgressSpinner />
  </div>
  <SearchPage v-else
              icon="marketing-search"
              :title="data.title">
    <template #controls>
      <span v-if="meta.count > 49"
            class="m-5 text-danger">
        {{ t('international.moreHits') }}
      </span>
      <Button :label="t('marketingResults.buttons.newSearch')"
              size="small"
              class="mr-1"
              @click="router.push({name: 'international.search'})" />
    </template>

    <template #content>
      <Results :columns="columns"
                :items="data.items"
                :meta="meta"
                :no-result-message="data.error ? data.error : t('marketingResults.noResultInternational')"
                :new-search-path="'international.search'" />
    </template>
  </SearchPage>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";
  import { http, t, router, route, init } from "@/logic";

  init();

  const loading = ref(false);
  const columns = ref([
    {
      value: "",
      prop: "country",
      type: "flag",
      width: "4%",      
    },
    {
      value: t("marketing.columns.name"),
      prop: "name",
      type: "url",
      width: "30%",
      class: "ellipsis",
    },
    {
      value: t("marketing.columns.address"),
      prop: "address",
      width: "40%",
      class: "ellipsis",
    },
    {
      value: t("marketing.columns.registrationNumber"),
      prop: "registrationNumber",
      width: "15%",
    }
  ]);
  const data = ref({
    items: [],
    title: "",
    error: null,
    count: 0,
  });
  const meta = ref({
    count: 0,
  });

  async function load() {
    await http.fetchWithLoading(data, loading, `/api/international/results?${new URLSearchParams(route.query).toString()}`);
    meta.value.count = data.value.count;
  }

  onMounted(async () => {
    await load();
  });
</script>
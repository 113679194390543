<template>
  <div class="companyDetails">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.name') }}</label>
          <InputText v-model="model.personName" id="member-name"
                     :placeholder="t('marketing.companyDetails.inputs.name')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.motherName') }}</label>
          <InputText v-model="model.motherName" id="member-mother-name"
                     :placeholder="t('marketing.companyDetails.inputs.motherName')" />
        </div>
        <div class="co-input-marketing multiple">
          <label for="">{{ t('marketing.companyDetails.inputs.address') }}</label>
          <div class="row m-initial w-100">
            <div class="col-12 col-md-2 p-initial">
              <InputText v-model="model.personZip" id="member-zip"
                         :placeholder="t('marketing.companyDetails.inputs.zip')" />
            </div>
            <div class="col-12 col-md-4 p-initial">
              <InputText v-model="model.personCity" id="member-city"
                         :placeholder="t('marketing.companyDetails.inputs.city')" />
            </div>
            <div class="col-12 col-md-6 p-initial">
              <InputText v-model="model.personStreet" id="member-street"
                         :placeholder="t('marketing.companyDetails.inputs.street')" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.personTaxID') }}</label>
          <InputText v-model="model.personTaxId" id="member-tax-id"
                     :placeholder="t('marketing.companyDetails.inputs.personTaxID')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.memberTaxNumber') }}</label>
          <InputText v-model="model.memberTaxNumber" id="member-tax-number"
                     :placeholder="t('marketing.companyDetails.inputs.memberTaxNumber')" />
        </div>
        <div class="co-input-marketing">
          <label for="">{{ t('marketing.companyDetails.inputs.type') }}</label>
          <MultiSelect v-model="model.memberType" id="member-type"
                       :options="memberTypes"  
                       option-label="value" 
                       :placeholder="t('multiselect.placeholder')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const model = defineModel() as any;
  import { t } from "@/logic";

  defineProps(["memberTypes"]);
</script>

<style scoped lang="scss">
.m-initial {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
</style>

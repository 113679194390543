<template>
  <div class="sidebar">
    <div v-if="!loading"
         ref="clickoutside"
         class="company-info">
      <div class="company-info-container">
        <div :class="{ 'active': isHamburgerActive, 'no-sidebar': $route.name === 'report.connections' }"
             class="hamburger-sidebar hamburger-sidebar--stack"
             @click="toggleHamburger">
          <div class="inner">
            <span class="bar" />
            <span class="bar" />
            <span class="bar" />
          </div>
        </div>
        <h2 class="company-name">
          {{ company.name }}
        </h2>
        <div class="company-status">
          <div class="icon"
               :class="company.status.icon.key"
               :style="{color: company.status.icon.color}" />
          <div class="status">
            <span>{{ company.status.value }}</span>
          </div>
        </div>
        <div class="company-rating">
          <div class="rating"
               :style="{backgroundColor: company.risk.backgroundColor}">
            {{ company.risk.value }}
          </div>
          <div class="status d-none d-lg-block">
            <span>{{ company.risk.label }}</span>
          </div>
        </div>
        <div v-if="company.type"
             class="company-status">
          <div class="icon"
               :class="company.type.icon" />
          <div class="status">
            <span>{{ company.type.name }}</span>
          </div>
        </div>
      </div>
      <div class="company-info-controls">
        <div v-if="showOnlyValid"
             class="co-checkbox-primary mr-1 d-none d-md-block">
          <Checkbox v-model="user.onlyValid"
                    input-id="onlyValid"
                    :binary="true" />
          <label for="onlyValid">
            <span class="d-none d-xl-inline">
              {{ t('companyData.checkboxes.onlyValidDetails') }}
            </span>
            <span class="d-xl-none">
              {{ t('companyData.checkboxes.onlyValidDetailsSmall') }}
            </span>
          </label>						
        </div>
        
        <a class="icon-button download"
           :href="downloadUrl()"
           target="_blank">
          <i class="icon export" />
          <span class="label d-none d-lg-inline">{{ t('main.sidebar.download') }}</span>            
        </a>

        <div class="icon-button"
             @click="toggleMonitoring">
          <span class="icon"
                :class="company.monitoring.icon.key"
                :style="{color: company.monitoring.icon.color}" />
          <span class="label d-none d-lg-inline">
            {{ company.monitoring.label }}
          </span>
        </div>


        <div class="dropdown b-dropdown dropdown-comparison monitoring-dropdown d-none d-md-block btn-group">
          <button aria-haspopup="true"
                  aria-expanded="true"
                  type="button"
                  class="btn dropdown-toggle btn-secondary">
            <div class="d-flex align-items-center"
                 @click="addToComparison">
              <span class="icon company-added" />
              <span class="d-none d-lg-inline-block">{{ t('summary.checkbox.comparison') }}</span>
            </div>
          </button>

          <ul v-if="comparisonCompanies.length > 0" 
              role="menu" 
              tabindex="-1" 
              class="dropdown-menu show"
              style=" position: absolute; top: 0; left: 0;width: initial; transform: translate3d(0px, 34px, 0px); will-change: transform;" 
              x-placement="bottom-start">
            <li v-for="(item, index) in comparisonCompanies"
                :key="item.id"
                role="presentation">
              <a role="menuitem"
                 href="#"
                 target="_self"
                 class="dropdown-item">
                <div class="d-flex align-items-center" 
                     style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                  <p class="text"
                     style=" max-width: 14rem;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ item.name }}</p>
                  <i class="icon company-deleted ml-2" 
                     style="position: absolute; right: 0;" 
                     @click="removeFromComparison(index)" />
                </div>
              </a>
            </li>
            <div v-if="comparisonCompanies.length > 1"
                 class="m-3 button button--primary button--small"
                 @click="$router.push({name: 'comparison'})">
              {{ t('main.sidebar.comparison.show') }}
            </div>
            <div v-if="comparisonCompanies.length > 1"
                 class="m-3 button button--primary button--small"
                 @click="removeAll()">
              {{ t('main.sidebar.comparison.removeall') }}
            </div>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <div :class="{ 'show': isHamburgerActive, 'no-sidebar': $route.name === 'report.connections' }"
           class="sidebar-content">
        <router-link v-if="company.menu.summary"
                     :to="{name: 'report.summary'}"
                     :class="{'active': $route.name === 'report.summary'}"
                     @click="toggleHamburger">
          <div class="icon summary" />
          <span>{{ t('main.sidebar.summary') }}</span>
        </router-link>
        <router-link v-if="company.menu.companyData"
                     :to="{name: 'report.company-data'}"
                     :class="{'active': $route.name === 'report.company-data'}"
                     @click="toggleHamburger">
          <div class="icon company-report" />
          <span>{{ t('main.sidebar.companyReport') }}</span>
        </router-link>
        <router-link v-if="company.menu.financialReportSummary"
                     :to="{name: 'report.financial-report-summary'}"
                     :class="{'active': $route.name === 'report.financial-report-summary'}"
                     @click="toggleHamburger">
          <div class="icon financial-reports" />
          <span>{{ t('main.sidebar.financialReportSummary') }}</span>
        </router-link>
        <router-link v-if="company.menu.financialReport"
                     :to="{name: 'report.financial-report'}"
                     :class="{'active': $route.name === 'report.financial-report'}"
                     @click="toggleHamburger">
          <div class="icon reports" />
          <span>{{ t('main.sidebar.financialReport') }}</span>
        </router-link>
        <router-link v-if="company.menu.commonRatings"
                     :to="{name: 'report.common-ratings'}"
                     :class="{'active': $route.name === 'report.common-ratings'}"
                     @click="toggleHamburger">
          <div class="icon common-ratings" />
          <span>{{ t('main.sidebar.commonRatings') }}</span>
        </router-link>
        <router-link v-if="company.menu.negativeInfo"
                     :to="{name: 'report.negative-info'}"
                     :class="{'active': $route.name === 'report.negative-info'}"
                     class="svg"
                     @click="toggleHamburger">
          <div class="icon negative-info"
               :style="{color: company.negativeColor}" />
          <span :style="{ 'color': company.negativeColor }">{{ t('main.sidebar.negativeInformations') }}</span>
        </router-link>
        <router-link v-if="company.menu.positiveInfo"
                     :to="{name: 'report.positive-info'}"
                     :class="{'active': $route.name === 'report.positive-info'}"
                     class="svg"
                     @click="toggleHamburger">
          <div class="icon positive-info"
               :style="{color: company.positiveColor}" />
          <span :style="{ 'color': company.positiveColor }">{{ t('main.sidebar.positiveInformations') }}</span>
        </router-link>
        <router-link v-if="company.menu.connections"
                     :to="{name: 'report.connections'}"
                     :class="{'active': $route.name === 'report.connections'}">
          <div class="icon connections" />
          <span>{{ t('main.sidebar.connectionNetwork') }}</span>
        </router-link>
        <router-link v-if="company.menu.connectionList"
                     :to="{name: 'report.connection-list'}"
                     :class="{'active': $route.name === 'report.connection-list'}"
                     @click="toggleHamburger">
          <div class="icon switch" />
          <span>{{ t('main.sidebar.connectionList') }}</span>
        </router-link>
        <router-link v-if="company.menu.timeline"
                     :to="{name: 'report.timeline'}"
                     :class="{'active': $route.name === 'report.timeline'}"
                     @click="toggleHamburger">
          <div class="icon timeline" />
          <span>{{ t('main.sidebar.timeline') }}</span>
        </router-link>
        <router-link v-if="company.menu.marketing"
                     :to="{name: 'report.marketing'}"
                     :class="{'active': $route.name === 'report.marketing'}"
                     @click="toggleHamburger">
          <div class="icon marketing" />
          <span>{{ t('main.sidebar.marketing') }}</span>
        </router-link>
        <router-link v-if="company.menu.sector"
                     :to="{name: 'report.sector'}"
                     :class="{'active': $route.name === 'report.sector'}"
                     @click="toggleHamburger">
          <div class="icon benefit-stat" />
          <span>{{ t('main.sidebar.departmentIndicators') }}</span>
        </router-link>
        <router-link v-if="company.menu.finalOwner"
                     :to="{name: 'report.final-owner'}"
                     :class="{'active': $route.name === 'report.final-owner'}"
                     @click="toggleHamburger">
          <div class="icon person" />
          <span>{{ t('main.sidebar.finalOwner') }}</span>
        </router-link>
        <router-link v-if="company.menu.im"
                     :to="{name: 'report.im'}"
                     :class="{'active': $route.name === 'report.im'}"
                     @click="toggleHamburger">
          <div class="icon kim" />
          <span>{{ t('main.sidebar.KIMCompanyInfo') }}</span>
        </router-link>
        <a v-if="company.menu.extraMenu"
           :href="company.menu.extraMenu.link"
           target="_blank">
          <div class="icon home-settings" />
          <span>{{ company.menu.extraMenu.name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted, watch } from "vue";
  import { http, t, toggle, toast, init, route, comparison, user } from "@/logic";
  import { onClickOutside, useEventBus } from "@vueuse/core";

  init();

  const loading = ref(false);
  const isHamburgerActive = ref(false);  
  const showOnlyValid = ref(false);  
  const company = reactive({
    id: "",
    name: "",
    tax8: "",
    positiveColor: "",
    negativeColor: "",
    menu: {} as any,
    status: {
      icon: {
        key: ""
      }
    } as any,
    risk: {} as any,
    type: {} as any,
    monitoring: {
      icon: {
        key: ""
      }
    } as any
  });
  const comparisonCompanies = ref(comparison.companies || []);
  const clickoutside = ref(null);

  useEventBus<boolean>("showOnlyValid").on(x => {
    showOnlyValid.value = x;
  });  

  onClickOutside(clickoutside, event => hide());

  function toggleHamburger() {
    toggle(isHamburgerActive);
  }

  function addToComparison() {
    comparison.addCompany({
      name: company.name,
      id: company.id,
    });
    comparisonCompanies.value = comparison.companies;
  }

  function removeFromComparison(index) {
    comparison.deleteCompany(index);
    comparisonCompanies.value = comparison.companies;
  }

  function removeAll() {
    comparison.emptyAll();
    comparisonCompanies.value = comparison.companies;
  }

  function hide() {
    isHamburgerActive.value = false;
  }

  async function toggleMonitoring() {
    const data = await http.get("/api/monitoring/" + company.tax8 + "/toggle");
    toast(data.notification, data.notificationColor);    
    company.monitoring = data.state;
  }

  async function load() {
    const data = await http.get(`/api/report/${route.params.id}/header`);
    company.id = data.id;
    company.name = data.name;
    company.tax8 = data.tax8;
    company.menu = data.menu;
    company.status = data.status;
    company.risk = data.risk;
    company.type = data.type;
    company.monitoring = data.monitoring;
    company.positiveColor = data.positiveColor;
    company.negativeColor = data.negativeColor;
  }

  function downloadUrl() {
    return `/report/${route.params.id}/print`;
  }

  onMounted(async () => {
    loading.value = true;
    await load();
    loading.value = false;
  });

  watch(
    () => route.params.id,
    load
  );
</script>

<style lang="scss" scoped>
  .sidebar {
    & .company-info {
      position: fixed;
      top: $height-nav;
      z-index: 900;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100vw;
      height: $height-company-info;
      padding: 0 $padding-nav;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 8px 0px;
      background-color: white;

      @include media-breakpoint-down(lg) {
        top: $height-nav + 3.5rem;
      }

      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        white-space: nowrap;

        & .company-name {
          margin: 0 1rem 0 0;
          overflow: hidden;
          color: $color-font-primary;
          font-weight: 500;
          font-size: 2rem;
          text-overflow: ellipsis;

          @include media-breakpoint-down(lg) {
            margin: 0 1rem;
          }
        }

        & .company-status {
          display: flex;
          align-items: center;
          margin-right: 1rem;

          @include media-breakpoint-down(lg) {
            margin-right: 0.5rem;
          }

          & .icon {
            display: flex;
            margin-right: 0.5rem;
            font-size: 2.5rem;

            &--active {
              &::after {
                color: white;
                font-size: 2rem;
                font-family: $font-icon;
                content: "\e906";
              }
            }
          }

          & .status {
            color: $color-font-primary;
            font-weight: 400;
            font-size: 1.4rem;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }

        & .company-rating {
          display: flex;
          align-items: center;
          margin-right: 1rem;

          & .rating {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.2rem;
            min-width: 2.2rem;
            height: 2.2rem;
            min-height: 2.2rem;
            margin-right: 0.5rem;
            padding-right: 0.1rem;
            color: white;
            font-weight: 700;
            font-size: 1.3rem;
            font-family: $font-monospace;
            line-height: 1;
            border-radius: 50%;

            &--active {
              background-color: $color-green;
            }
          }

          & .status {
            color: $color-font-primary;
            font-weight: 400;
            font-size: 1.4rem;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
      }

      &-controls {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    & .sidebar-content {
      position: fixed;
      top: $height-nav + $height-company-info;
      bottom: 0;
      z-index: $z-index-sidebar;
      display: flex;
      flex-direction: column;
      width: $width-sidebar-desktop;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: $color-sidebar-bg;
      transition: width 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

      &::-webkit-scrollbar {
        display: none;
      }

      @include media-breakpoint-up(lg) {
        &.no-sidebar {
          width: $width-sidebar-mobile;

          &.show {
            width: $width-sidebar-desktop;
            box-shadow: $box-shadow-sidebar;

            & a {
              &.active {
                border-right: 3px solid $color-primary;
              }
            }
          }
        }
      }

      @include media-breakpoint-down(lg) {
        top: $height-nav + $height-company-info + 3.5rem;
        width: $width-sidebar-mobile;

        &.show {
          width: $width-sidebar-desktop;
          box-shadow: $box-shadow-sidebar;

          & a {
            &.active {
              border-right: 3px solid $color-primary;
            }
          }
        }
      }

      & a {
        display: flex;
        align-items: center;
        margin-left: $padding-nav;
        padding: 1rem;
        color: $color-font-primary;
        font-weight: 500;
        font-size: 1.4rem;
        white-space: nowrap;
        text-decoration: none;
        border-right: 3px solid transparent;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-right 0.2s ease-in-out;

        &.active {
          color: $color-primary;
          background-color: white;
          cursor: pointer;
          opacity: 1;

          & .icon {
            color: $color-primary;
          }
        }

        &:hover {
          color: $color-primary;
          background-color: white;
          cursor: pointer;
          opacity: 1;

          & .icon {
            color: $color-primary;
          }
        }


        & .icon {
          margin-right: 0.5rem;
          font-size: 2rem;
          transition: color 0.2s ease-in-out;
        }

        &.svg {
          & svg {
            width: 2rem;
            min-width: 2rem;
            height: 2rem;
            min-height: 2rem;
            margin-right: 0.5rem;
          }
        }

        & span {
          line-height: 1;
        }
      }
    }

    .monitoring-dropdown {
      & button {
        padding: 0 !important;
      }

      ul {
        width: 30rem;
      }

      & .company-added {
        display: inline;
        margin-right: 0.5rem;
        color: $color-primary;
        font-size: 2.6rem;
      }
    }

    .icon-button {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      font-size: 1.4rem;
      cursor: pointer;

      & .icon {
        display: inline;
        color: $color-primary;
        font-size: 2.6rem;
      }

      & .label {
        margin-left: 0.5rem;
      }
    }

    .dropdown-user-select .show ul {
      top: 0 !important;
    }

    .download {
      color: $color-font-primary;
      text-decoration: none;
    }
  }
</style>

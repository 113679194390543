<template>
  <div v-if="!loading"
       class="page-company-data">
    <div class="container-fluid p-initial">
      <div class="row">
        <div class="col-12 col-xxl-8">
          <PageCard :title="t('companyData.title')"
                    icon="company-report">
            <template #controls>
              <div class="d-flex flex-column">
                <div class="co-checkbox-primary" />
              </div>
              <div v-if="!isPrint"
                   class="d-flex flex-row">
                <Button v-if="!downloadStarted"
                        size="small"
                        :label="t('companyData.buttons.downloadExtract')"
                        @click="download" />
                <div v-else
                     class="mt-2">
                  <span class="spinner-border text-primary" />
                  <span class="ml-1 text-primary">{{ t('companyData.buttons.downloading') }}</span>
                </div>
                <div class="d-none d-md-block">
                  <Button v-if="openAll === false"
                          size="small"
                          :label="t('negativeInfos.buttons.openAll')"
                          icon="pi pi-angle-down"
                          icon-pos="right"
                          @click="onOpenAll" />
                  <Button v-else
                          size="small"
                          :label="t('negativeInfos.buttons.closeAll')"
                          icon="pi pi-angle-up"
                          icon-pos="right"
                          @click="onOpenAll" />
                </div>                
              </div>
            </template>

            <template #content>
              <EmptyState v-if="!companyData.groups.length"
                          page="companyData" />
              <TabView v-else>
                <TabPanel :header="t('companyData.tabs.companyData')">
                  <CompanyDataContent :groups="companyData.groups"
                                      :has-more="companyData.hasMore"
                                      :only-valid="onlyValid"
                                      @more-data="onMoreData" />
                </TabPanel>
                <TabPanel :header="t('companyData.tabs.members')">
                  <CompanyDataContent :groups="membersGroups"
                                      :has-more="companyData.hasMore"
                                      :only-valid="onlyValid"
                                      @more-data="onMoreData" />
                </TabPanel>
                <TabPanel :header="t('companyData.tabs.news')">
                  <CompanyDataContent :groups="newsGroups"
                                      :only-valid="onlyValid"
                                      @more-data="onMoreData" />
                </TabPanel>
              </TabView>
            </template>
          </PageCard>
        </div>
        <div class="col-12 col-xxl-4 did-you-know-card">
          <DidYouKnow page-name="company-data"
                      :sticky="true"
                      alternative-card="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, computed, onMounted, onUnmounted } from "vue";
  import { http, t, toggle, route, init, user } from "@/logic";
  import { useEventBus } from "@vueuse/core";

  init();

  const { defaultAll } = defineProps<{
    defaultAll?: boolean;
  }>()


  const collapseEventBus = useEventBus<boolean>("companyData/collapseToggle");
  const showOnlyValidEventBus = useEventBus<boolean>("showOnlyValid");
  const loading = ref(false);
  const all = ref(defaultAll);
  const openAll = ref(false);
  const downloadStarted = ref(false);
  const isPrint = ref(false);

  const companyData = reactive({
    downloadUrl: "",
    groups: [],
    hasMore: false
  });

  const membersGroups = computed(() => {
    return companyData.groups.filter((g) => g.type === "members");
  });

  const newsGroups = computed(() => {
    return companyData.groups.filter((g) => g.type === "news");
  });

  const onlyValid = computed(() => {
    var onlyValid = user.onlyValid ||
      route.path.includes("print/extract") ||
      (route.path.includes("print") && route.fullPath.includes("onlyValid=true"));
    return onlyValid;
  });

  async function load() {
    const data = (await http.get("/api/report/" + route.params.id + "/data?all=" + all.value));
    companyData.downloadUrl = data.downloadUrl;
    companyData.groups = data.groups;

    openAll.value = user.dataExpanded || route.path.includes("print");

    if (openAll.value && companyData.groups) {
      companyData.groups.forEach((x) => (x.open = true));
    }
  }

  function onOpenAll() {
    toggle(openAll);
    collapseEventBus.emit(openAll.value);
  }

  async function onMoreData() {
    all.value = true;
    await load();
  }

  async function download() {
    await http.blob("/api/report/" + route.params.id + "/print-extract", downloadStarted);		
  }

  onMounted(async () => {
    loading.value = true;
    await load();
    loading.value = false;
    if (!route.path.includes("print")) {    
      showOnlyValidEventBus.emit(true);      
    }
  });

  onUnmounted(() => {
    showOnlyValidEventBus.emit(false);
  });
</script>

<style lang="scss" scoped>
.page-company-data {
  & :deep(.card) {
    margin-right: 0;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
</style>

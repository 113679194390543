<template>
    <div class="companyDetails">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="co-input-marketing multiple">
            <label for="">{{ t('marketing.companyDetails.inputs.address') }}</label>
            <div class="row m-initial w-100">
              <div class="col-12 col-md-2 p-initial">
                <InputText v-model="model.zip" id="company-zip"
                           :placeholder="t('marketing.companyDetails.inputs.zip')" />
              </div>
              <div class="col-12 col-md-4 p-initial">
                <InputText v-model="model.city" id="company-city"
                           :placeholder="t('marketing.companyDetails.inputs.city')" />
              </div>
              <div class="col-12 col-md-6 p-initial">
                <InputText v-model="model.street" id="company-street"
                           :placeholder="t('marketing.companyDetails.inputs.street')" />
              </div>
            </div>
          </div>
          <div class="co-input-marketing">
            <label for="">{{ t('marketing.companyDetails.inputs.addressType') }}</label>
            <MultiSelect v-model="model.addressType" id="company-address-type"
                         :options="addressTypes" 
                         option-label="value" 
                         :placeholder="t('multiselect.placeholder')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="co-input-marketing">
            <label for="">{{ t('marketing.companyDetails.inputs.county') }}</label>
            <MultiSelect v-model="model.county" id="company-county"
                         :options="prefill.counties" 
                         option-label="value"
                         :placeholder="t('multiselect.placeholder')" />
          </div>
          <div class="co-input-marketing">
            <label for="">{{ t('marketing.companyDetails.inputs.region') }}</label>
            <MultiSelect v-model="model.region" id="company-region"
                         :options="prefill.regions" 
                         option-label="value" 
                         :placeholder="t('multiselect.placeholder')" />
          </div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
    const model = defineModel() as any;
    import { t } from "@/logic";
    defineProps(["prefill", "addressTypes"]);
  </script>
  
  <style scoped lang="scss">
  .m-initial {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  </style>
  
<template>
  <div v-show="loading" class="text-center">
    <ProgressSpinner />
  </div>
  <div v-show="!loading" class="interactive-table"
       :class="{ 'insideMarketingCard': isParentMarketingCard }">
    <div v-if="meta.count == 0"
         class="text-center mt-5">
      {{ noResultMessage || t('emptyTable') }}
    </div>
    <div v-else class="interactive-table__table">
      <div class="interactive-table__header">
        <div v-for="(column, index) in columns"
             :key="'column-' + index"
             class="interactive-table__header__company-name d-flex align-items-center"
             :class="[column.class, {active: meta.orderBy === column.prop, cursor: column.orderable},]"
             :style="{width: column.width}"
             @click="onOrderBy(column)">
          {{ column.value }}
          <div v-if="column.orderable"
               class="ml-1">
            <i v-if="meta.orderBy === column.prop && meta.order === 'desc'"
               class="icon sorter-down-active" />
            <i v-else-if="meta.orderBy === column.prop && meta.order === 'asc'"
               class="icon sorter-up-active" />
            <i v-else
               class="icon sorter-down" />
          </div>
        </div>
      </div>
      <div v-for="(row, index) in items"           
           :key="'row-' + index"
           class="interactive-table__table__row align-items-center">
        <div v-for="(col, colIndex) in columns"
             :key="'col-' + index + '-' + colIndex"
             class="interactive-table__table__row__company-name"
             :style="{width: col.width}"
             :class="col.class">
          <div class="interactive-table__table__row__title">
            {{ col.value }}
          </div>

          <!-- icon type -->
          <div v-if="col.type === 'icon'"
               class="d-flex align-items-start justify-content-md-left">
            <div class="interactive-table__table__row__status__icon icon"
                 :class="row[col.prop].icon.key"
                 :style="{color: row[col.prop].icon.color}" />
            <div class="interactive-table__table__row__data ml-2 d-block d-md-none d-lg-block"
                 :style="{color: row[col.prop].icon.color}">
              {{ row[col.prop].value }}
            </div>
          </div>

          <!-- url type -->
          <div v-else-if="col.type === 'url'"
               class="interactive-table__table__row__data">
            <router-link :to="row[col.prop].url">
              {{ row[col.prop].value }}
            </router-link>
          </div>

          <div v-else-if="col.type === 'link'"
               class="interactive-table__table__row__data">
            <a :href="row[col.prop].url"
               target="_blank">{{ row[col.prop].value }}</a>
          </div>

          <!-- marketing type -->
          <div v-if="col.type === 'marketing'"
               class="d-flex">
            <a v-if="row[col.prop].phone"
               :href="'tel:' + row[col.prop].phone"
               class="interactive-table__table__row__marketing__icon"
               style="color: #f3792b ;">
              <i class="icon marketing-phone" />
            </a>
            <div v-else
                 class="interactive-table__table__row__marketing__icon"
                 style="color: #CDCED2 ;">
              <i class="icon marketing-phone" />
            </div>
            <a v-if="row[col.prop].email"
               :href="'mailto:' + row[col.prop].email"
               class="interactive-table__table__row__marketing__icon"
               style="color: #f3792b;">
              <i class="icon marketing-mail" />
            </a>
            <div v-else
                 class="interactive-table__table__row__marketing__icon"
                 style="color: #CDCED2;">
              <i class="icon marketing-mail" />
            </div>
            <a v-if="row[col.prop].website"
               :href="row[col.prop].website"
               class="interactive-table__table__row__marketing__icon"
               style="color: #f3792b;">
              <i class="icon marketing-web" />
            </a>
            <div v-else
                 class="interactive-table__table__row__marketing__icon"
                 style="color: #CDCED2;">
              <i class="icon marketing-web" />
            </div>
          </div>

          <!-- colored type -->
          <div v-if="col.type === 'colored'"
               class="interactive-table__table__row__data"
               :style="{color: row[col.prop].color}">
            {{ row[col.prop].value }}
          </div>

          <!-- flag type -->
          <div v-if="col.type === 'flag'"
               class="interactive-table__table__row__data">
            <span :class="'fi fi-' + row[col.prop]"></span>
          </div>

          <!-- no type -->
          <div v-if="col.type === undefined"
               class="interactive-table__table__row__data">
            {{ row[col.prop] }}
          </div>

          <!-- slot type -->
          <div v-if="col.type === 'slot'"
               class="interactive-table__table__row__data"
               :class="col.class">
            <slot :name="col.prop"
                  :row="row" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import ProgressSpinner from "primevue/progressspinner";
  import { t } from "@/logic";

  const props = withDefaults(defineProps<{
    columns: Array<any>,
    items: Array<any>,
    meta?: any,
    isParentMarketingCard?: boolean,
    loading?: boolean,
    noResultMessage?: string,
  }>(), {
    meta: {},
    isParentMarketingCard: false
  });
  
  const emit = defineEmits<{
    (e: "onOrderBy", column: any): void;
  }>();

  function onOrderBy(column) {
    if (column.orderable) {
      emit("onOrderBy", column.prop);
    }
  }
</script>

<style lang="scss" scoped>
.interactive-table {
  &.insideMarketingCard {
    position: relative;
    top: -2.5rem;
    margin-bottom: -4rem;
  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 2px solid $color-border-secondary;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &__company-name,
    &__headquarter,
    &__company-number,
    &__tax-number,
    &__status,
    &__marketing {
      display: flex;
      padding-right: 1rem;
      color: $color-font-quaternary;
      font-size: 1.4rem;
      text-transform: uppercase;

      &.active {
        color: $color-font-primary;
      }
    }

    & > div:first-child {
      margin-left: 1rem;
    }

    &__company-number,
    &__tax-number,
    &__status,
    &__marketing {
      min-width: 12rem;
    }

    &__status,
    &__marketing {
      min-width: 10rem;
      text-align: center;
    }
  }

  &__table {
    &__row {
      display: flex;
      padding: 1rem 0;
      border-bottom: 2px solid $color-border-secondary;
      border-left: 4px solid transparent;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      & > div:first-child {
        margin-left: 1rem;
      }

      &:last-child {
        border-bottom: none;
      }

        &:hover {
          background-color: $color-table-content-bg;
          //border-left: 4px solid $color-primary;
        }


      & .icon {
        margin-right: 0.5rem;
        font-size: 2rem;
      }

      &__title {
        display: none;
        margin-bottom: 0.5rem;
        color: $color-font-quaternary;
        font-weight: 500;
        font-size: 1.6rem;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
          display: block;
        }
      }

      &__data {
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(md) {
          margin-bottom: 2rem;
        }
      }

      &__company-name,
      &__headquarter,
      &__company-number,
      &__tax-number,
      &__status,
      &__marketing {
        /* margin-left: 2rem; */
        padding-right: 1rem;
        color: $color-font-primary;
        font-size: 1.4rem;

        @include media-breakpoint-down(md) {
          width: 100% !important;
          margin-left: 1rem;
        }
      }

      &__company-number,
      &__tax-number,
      &__status,
      &__marketing {
        min-width: 12rem;
      }

      &__status,
      &__marketing {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        min-width: 10rem;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }
    }
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cursor {
    cursor: pointer;
  }
}
</style>

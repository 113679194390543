<template>
  <div class="container">
    <div class="print-co-header no-color" v-if="company.details">
      <h1>{{ t('extract.title') }}</h1>
      <p v-html="t('extract.header', { 
        regNumber: company.details.registrationNumber?.value, 
        name: company.details.name, 
        headquarter: company.details.headquarter, 
        date: date 
      })" />
    </div>
    <div class="no-color print-view">
      <CompanyData />
    </div>

    <main style="display: none" />
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from "vue";  
  import {useRoute } from "vue-router";  
  import { http, t } from "@/logic";
  import CompanyData from "../report/CompanyData.vue";

  const route = useRoute();
  const company = ref({} as any);
  const date = ref("");

  async function getCompany() {
    await http.fetch(company, "/api/report/" + route.params.id + "/summary");
    console.log(company.value);
  }

  onMounted(async () => {
    await getCompany();
    date.value = new Date().toLocaleDateString("hu");
  });
</script>

<style scoped lang="scss">
.print-view:deep(.no-print) {
  display: none;
}

.print-co-header {
  justify-content: space-between;

  & img {
    height: 6rem;
  }

  & p {
    margin-top: 1rem;
    font-style: italic;
  }
}

  :deep() {
    .tab-header, .p-tabview-nav {
      display: none !important;
    }

    .co-collapse {
      border-top: none !important;
    }

    .col-xxl-8 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    h3 {
      display: none !important;
    }

    & .widget-controller,
    & .alternativeCard,
    & .map-marker,
    & .copy,
    & .inner-wrapper-sticky,
    & .button,
    & .sort-up,
    & .sort-down,
    & .co-checkbox-primary {
      display: none !important;
    }

    & .data-section {
      margin-bottom: 1rem;
    }

    & .line--card-separator {
      margin: 0.5rem 0 !important;
    }

    & .cc-title {
      font-size: 3rem !important;
    }

    & .cc-address a {
      display: none !important;
    }

    & table a {
      color: $color-font-primary !important;
      text-decoration: none !important;
    }

    & table td {
      padding: 0.25rem 0.5rem !important;
    }

    & .card {
      margin: 0 !important;
      padding: 2rem !important;
      break-inside: avoid !important;
      page-break-inside: avoid !important;
      border: 1px solid $color-border-secondary !important;

      &.alternativeCard {
        display: none !important;
      }

      &:first-child {
        margin-bottom: 1rem !important;
      }

      &__header {
        margin-bottom: 0 !important;

        &__graph,
        &__dropdown {
          display: none !important;
        }

        &__title {
          margin-bottom: 3rem !important;
        }

        &__info {
          display: none !important;
        }
      }

      &__content {
        display: block !important;

        & td {
          padding: 0 !important;
        }

        & .cardCompanyDetails {
          & .title {
            min-width: 22rem !important;

            @media print {
              color: black !important;
            }
          }

          & .badge {
            border: none !important;

            @media print {
              color: black !important;
            }
          }
        }
      }
    }

    & .print-percent-50 {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }

    & .print-percent-100 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    & .year-container {
      & .year.active {
        @media print {
          color: black !important;
        }
      }
    }

    & .tab-container {
      box-shadow: none !important;
    }

    & .collapse {
      display: block !important;

      & .title {
        &::after {
          display: none !important;
        }
      }
    }

    & .tabs {
      & .nav-tabs {
        display: none !important;
      }

      & ul,
      & .nav-tabs {
        border-bottom: none !important;
      }
    }

    & .co-collapse {
      // border-top: none !important;
      padding-top: 3rem !important;

      & .title {
        color: $color-font-primary !important;

        &::after {
          display: none !important;
        }
      }
    }
  }

.company-deleted {
  font-size: 3rem;
}

:deep() {
  & .print-row {
    display: block !important;
  }

  & .print-percent-50 {
    display: block !important;
    float: left !important;
    page-break-inside: avoid !important;
  }

  & .print-new-page {
    display: block !important;
    page-break-before: always !important;
  }
}
</style>

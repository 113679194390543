<template>
  <div v-if="!loading"
       class="page-summary">
    <div class="container-fluid p-initial">
      <div class="row">
        <div class="col-12">
          <div v-if="loaded"
               class="row print-row">
            <div v-for="widget in widgets"
                 :key="widget.name"
                 :class="widget?.class + ' print-avoid-page-brake'">
              <component :is="widget.component"
                         v-if="company && widget.show"
                         :key="widget.name"
                         :chart-title="widget.title"
                         :icon="widget.icon"
                         :info-text="widget.infoText"
                         :data="widget.data()" />
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <WidgetController :list="widgets"
                            group="report"
                            @update="widgetUpdate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, markRaw, watch, onBeforeMount } from "vue";
  import { useRoute } from "vue-router";
  import { http, t } from "@/logic";
  import CompanyDetails from "@/components/pages/report/CompanyDetails.vue";
  import RatingDetails from "@/components/pages/report/RatingDetails.vue";
  import Contacts from "@/components/pages/report/Contacts.vue";
  import AuthorizedToSign from "@/components/pages/report/AuthorizedToSign.vue";
  import NonCompanyDetails from "@/components/pages/report/NonCompanyDetails.vue";
  import OtherCompanies from "@/components/pages/report/OtherCompanies.vue";
  import ChartCard from "@/components/ChartCard.vue";

  const route = useRoute();
  const key = ref(0);
  const loading = ref(false);
  const loaded = ref(false);
  const company = ref(null);

  const widgets = ref([
    {
      name: "CompanyDetails",
      component: markRaw(CompanyDetails),
      title: t("summary.card.widgets.companyDetails"),
      class: "col-12",
      data: () => company.value,
      show: true
    },
    {
      name: "OtherCompanies",
      component: markRaw(OtherCompanies),
      title: t("summary.card.widgets.otherCompanies"),
      data: () => company.value,
      show: true
    },
    {
      name: "RatingDetails",
      component: markRaw(RatingDetails),
      title: t("summary.card.widgets.ratingDetails"),
      class: "col-12",
      data: () => company.value,
      show: false
    },
    {
      name: "Classification",
      component: markRaw(ChartCard),
      title: t("summary.card.classification.title"),
      icon: "summary",
      infoText: t("summary.card.classification.infoText"),
      data: () => company.value.charts?.classification?.data,
      class: "col-12 col-lg-6 print-percent-50",
      show: true
    },
    {
      name: "Revenue",
      component: markRaw(ChartCard),
      title: t("summary.card.revenue.title"),
      icon: "financial-reports",
      infoText: t("summary.card.revenue.infoText"),
      data: () => company.value.charts?.revenue?.data,
      class: "col-12 col-lg-6 print-percent-50",
      show: true
    },
    {
      name: "Contacts",
      component: markRaw(Contacts),
      title: t("summary.card.widgets.contacts"),
      class: "col-12",
      data: () => company.value,
      show: true
    },
    {
      name: "AuthorizedToSign",
      component: markRaw(AuthorizedToSign),
      title: t("summary.card.widgets.authorizedToSign"),
      class: "col-12",
      data: () => company.value.authorizedToSign,
      show: true
    },
    {
      name: "Roe",
      component: markRaw(ChartCard),
      title: t("summary.card.roe.title"),
      icon: "financial-reports",
      infoText: t("summary.card.roe.infoText"),
      data: () => company.value.charts?.roe?.data,
      class: "col-12 col-lg-6 print-percent-50",
      show: true
    },
    {
      name: "Employees",
      component: markRaw(ChartCard),
      title: t("summary.card.employees.title"),
      icon: "profile-user",
      infoText: t("summary.card.employees.infoText"),
      data: () => company.value.charts?.employees?.data,
      class: "col-12 col-lg-6 print-percent-50",
      show: true
    },
    {
      name: "Result",
      component: markRaw(ChartCard),
      title: t("summary.card.result.title"),
      icon: "income",
      infoText: t("summary.card.result.infoText"),
      data: () => company.value.charts?.result?.data,
      class: "col-12 col-lg-6 print-percent-50",
      show: true
    },
    {
      name: "OwnCapital",
      component: markRaw(ChartCard),
      title: t("summary.card.ownCapital.title"),
      icon: "income",
      infoText: t("summary.card.ownCapital.infoText"),
      data: () => company.value.charts?.ownCapital?.data,
      class: "col-12 col-lg-6 print-percent-50",
      show: true
    },
  ]);

  async function load() {
    await http.fetch(company, `/api/report/${route.params.id}/summary`);

    Object.keys(company.value.empty).forEach(function(name) {
      const widget = widgets.value.find(x => x.name == name.charAt(0).toUpperCase() + name.slice(1));
      if (widget) {
        widget.show = !company.value.empty[name];
      }
    });
  }

  function widgetUpdate(updatedWidgets) {
    widgets.value = updatedWidgets;
    loaded.value = true;
  }

  function removeComponent(name) {
    const index = widgets.value.findIndex(x => x.name == name);
    widgets.value.splice(index, 1);   
  }

  onBeforeMount(async () => {
    loading.value = true;
    await load();
    
    if (company.value.type === "selfemployed" ||
      company.value.type === "nonprofit" ||
      company.value.type === "budgetary" ||
      company.value.type === "educational" ||
      company.value.type === "taxentity") {     
      widgets.value.find(x => x.name == "CompanyDetails").component = NonCompanyDetails;
    }

    if (!company.value.otherCompanies) {
      removeComponent("OtherCompanies"); 
    }

    if (!company.value.authorizedToSign || !company.value.authorizedToSign.length) {  
      removeComponent("AuthorizedToSign");   
    }

    if (!company.value.contacts ||
      (!company.value.contacts.phone == null &&
        !company.value.contacts.email == null &&
        !company.value.contacts.website == null)
    ) {
      removeComponent("Contacts");   
    }

    loading.value = false;
  });

  watch(() => route.params.id, function () {
    load();
    key.value++;
  });
</script>

<style lang="scss" scoped>
.print-avoid-page-brake {
  page-break-inside: avoid !important;
}
</style>
